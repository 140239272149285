.logo-text {
  color: #0c1d56;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .logo {
    margin-left: 0%;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 800px) {
  .logo {
    margin-left: -2%;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 801px) and (max-width: 990px) {
  .logo {
    margin-left: -5%;
  }
}

/* Media Query for Tablets Ipads */
@media (min-width: 991px) and (max-width: 1100px) {
  .logo {
    margin-left: 0%;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1101px) and (max-width: 1200px) {
  .logo {
    margin-left: -3%;
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  .logo {
    margin-left: -4%;
  }
}

.wrapper-class {
  display: flex;
  flex-direction: row-reverse;
}

@media only screen and (min-width: 1281px) {
  .wrapper-class {
    width: 95%;
  }
}
